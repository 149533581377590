import React, { useRef } from 'react'

export const ImageModal = ({
  setPreview,
  setCurrentImage,
  currentImage,
  className = `max-w-3xl max-h-50 object-cover`,
}) => {
  const modalRef = useRef(null)

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setPreview(false)
      setCurrentImage(``)
    }
  }
  return (
    <div
      id="modal"
      className="fixed top-0 left-0 z-50 w-screen h-screen bg-gray-900 bg-opacity-70 flex justify-center items-center"
      onClick={handleClickOutsideModal}
    >
      <a
        className={`fixed z-50 top-1 right-6 text-5xl font-bold text-white`}
        href="javascript:void(0)"
        onClick={() => {
          setPreview(false)
          setCurrentImage(``)
        }}
      >
        &times;
      </a>
      <img
        ref={modalRef}
        id="modal-img"
        src={currentImage}
        alt="Image Not Found"
        className={className}
      />
      {/* <img
     
      className="max-w-[800px] max-h-[600px] object-cover"
    /> */}
    </div>
  )
}

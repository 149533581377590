import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { Container, PageHeader } from 'App/Styled'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import dateFormat from 'App/Services/General/dateFormat'
// import { RichTextEditor } from 'App/Components/Common/RichTextEditor'

export const CampaignPreview = () => {
  const {
    params: { logId },
  } = useRouteMatch()

  const {
    data: campaignPreviewApi,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `campaignPreviewLog`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/broadcasts/log/${logId}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Campaign Preview</div>
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="flex flex-row m-3  ">
            <div className="flex-1">
              {/* Basic Info Start name email and gender */}

              <div className="rounded-md overflow-hidden py-4">
                <div className="flex flex-row justify-evenly gap-4 ">
                  <div className="flex-1">
                    <div className="grid grid-cols-12 px-4 py-2">
                      <div className="col-span-6 text-xl">General Info</div>
                    </div>
                    <hr />

                    <div className="flex px-4">
                      <div className="flex-1">
                        <CardField
                          title="ID"
                          value={campaignPreviewApi?._broadcastLogId}
                        />
                        <CardField
                          title="Broadcast Id"
                          value={campaignPreviewApi?._broadcastId}
                        />
                        <CardField
                          title="Channel"
                          value={campaignPreviewApi?.channel}
                        />
                        <CardField
                          title="Created At"
                          value={dateFormat(campaignPreviewApi?.createdAt)}
                        />
                        <CardField
                          title="Updated At"
                          value={dateFormat(campaignPreviewApi?.updatedAt)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="grid grid-cols-12 px-4 py-2">
                      <div className="col-span-6 text-xl">Receipt</div>
                    </div>
                    <hr />

                    <div className="flex px-4">
                      <div className="flex-1">
                        <CardField
                          title="Contact Info"
                          value={campaignPreviewApi?.contactInfo}
                        />
                        <CardField
                          path={
                            campaignPreviewApi?.scope == `CUSTOMERS`
                              ? `/customers/${campaignPreviewApi?.recipient?._id}`
                              : campaignPreviewApi?.scope == `LEADS`
                              ? `/crm/leads/${campaignPreviewApi?.recipient?._id}`
                              : campaignPreviewApi?.scope == `QUOTES`
                              ? `/raters/myquotes/${campaignPreviewApi?.recipient?._id}`
                              : ``
                          }
                          title="Receipt ID"
                          value={campaignPreviewApi?.recipient?.recipientId}
                        />
                        {campaignPreviewApi?.recipient?.recipentName ? (
                          <CardField
                            path={
                              campaignPreviewApi?.scope == `CUSTOMERS`
                                ? `/customers/${campaignPreviewApi?.recipient?._id}`
                                : campaignPreviewApi?.scope == `LEADS`
                                ? `/crm/leads/${campaignPreviewApi?.recipient?._id}`
                                : campaignPreviewApi?.scope == `QUOTES`
                                ? `/raters/myquotes/${campaignPreviewApi?.recipient?._id}`
                                : ``
                            }
                            title="Receipt Name"
                            value={campaignPreviewApi?.recipient?.recipentName}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-evenly gap-4 ">
                  <div className="flex-1 mt-4">
                    <div className="grid grid-cols-12 px-4 py-2">
                      <div className="col-span-6 text-xl">Body</div>
                    </div>
                    <hr />

                    <div className="flex px-4">
                      {campaignPreviewApi.channel === `SMS` ? (
                        <p className="text-sm text-gray-600 py-2">
                          {campaignPreviewApi?.body}
                        </p>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: campaignPreviewApi?.body,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex-1 mt-4">
                    <div className="grid grid-cols-12 px-4 py-2">
                      <div className="col-span-6 text-xl">Attempts</div>
                    </div>
                    <hr />
                    {campaignPreviewApi.attempts
                      .reverse()
                      .map((attempt, index) => (
                        <div
                          className="border-l-2 border-blue-500 px-2 mt-1"
                          key={index}
                        >
                          <span
                            className={`text-sm text-white py-1 px-2 rounded-md ${
                              attempt.status == STATUS.SKIPPED
                                ? `bg-orange-400`
                                : attempt.status == STATUS.DELIVERED
                                ? `bg-green-400`
                                : `bg-red-600`
                            }`}
                          >
                            {attempt.status}
                          </span>
                          <span className="text-xs px-2 italic text-gray-400">
                            {dateFormat(attempt?.createdAt)}
                          </span>
                          <div className="text-md my-2">
                            {attempt.statusText}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
